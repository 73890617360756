@use '../utils' as *;

.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: 0.45s ease-in-out;
  &.apply {
    opacity: 1;
    visibility: visible;
  }
  &:hover {
    //cursor: url(../img/cross-out.png), pointer;
  }
}
.it-instagram {
  & img {
    width: 100%;
  }
}
.itoffcanvas {
  position: fixed;
  top: 0;
  right: -100%;
  width: 480px;
  bottom: 0;
  z-index: 9999;
  padding: 80px 50px;
  scrollbar-width: none;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  transition: 0.45s ease-in-out;
  background-color: var(--it-common-white);
  box-shadow: 0 16px -32px 0 rgba(0, 0, 0, 0.8);
  &.opened {
    opacity: 1;
    visibility: visible;
  }
  @media #{$xs,} {
    width: 300px;
    padding: 40px 35px;
  }
  @media #{$sm} {
    width: 400px;
    padding: 40px;
  }
  &.opened {
    right: 0;
    -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-duration: 0.6s;
  }

  &__logo {
    margin-bottom: 40px;
    @media #{$xs,$md} {
      padding-top: 0;
    }
  }

  &__close-btn {
    & button {
      font-size: 35px;
      color: var(--it-common-black);
      position: absolute;
      right: 20px;
      top: 20px;
      transition: 1s;
      font-weight: 300;
      opacity: 0.5;
      -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transition-duration: 0.3s;
      @media #{$md,$xs} {
        font-size: 28px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  &__content {
    margin-bottom: 30px;
    & p {
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      line-height: 1.6;
      margin-bottom: 25px;
    }
    & span {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
      display: inline-block;
    }
    & a {
      font-size: 30px;
      line-height: 51px;
      font-weight: 700;
      color: var(--it-common-white);
      display: inline-block;
      @media #{$xs} {
        font-size: 27px;
      }
    }
  }
  &__social {
    margin-top: 50px;
    & .social-icon {
      & a {
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 18px;
        background-color: var(--it-theme-1);
        color: var(--it-common-white);
        margin-right: 15px;
        transition: 0.3s;
        display: inline-block;
        border-radius: 50%;
        @media #{$xs} {
          margin-right: 3px;
        }
        &:hover {
          background-color: var(--it-theme-1);
          color: var(--it-common-white);
        }
      }
    }
  }
  &__text {
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media #{$md,$xs} {
      display: none;
    }
    & p {
      color: var(--it-common-black);
      font-size: 16px;
    }
  }
  &__info {
    border-bottom: 1px solid #3b38382e;
    padding-bottom: 30px;
    margin-bottom: 20px;
    & .offcanva-title {
      color: var(--it-common-black);
      margin: 30px 0 40px 0;
      font-size: 20px;
      font-weight: 700;
    }
    &-icon {
      & a {
        height: 50px;
        width: 50px;
        background-color: var(--it-theme-1);
        color: var(--it-common-white);
        display: inline-block;
        text-align: center;
        line-height: 50px;
        border-radius: 50px;
        font-weight: 400;
        font-size: 21px;
        margin-right: 20px;
      }
    }
    &-address {
      text-align: left;
      & span {
        display: block;
        color: var(--it-common-black);
        font-size: 16px;
        font-weight: 400;
      }
      & a {
        display: block;
        color: var(--it-common-black);
        font-size: 18px;
        font-weight: 600;
        transition: 0.3s;
        @media #{$xs} {
          font-size: 16px;
        }
        &:hover {
          color: var(--it-theme-1);
        }
      }
    }
  }
}

.it-offcanva-bottom-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  & img {
    width: 100%;
  }
}
