@use '../utils' as *;

.ed-btn-theme{
	display: inline-block;
	padding: 0 25px;
	padding-right: 80px;
	height: 58px;
	line-height: 58px;
	position: relative;
	transition: .3s;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.01em;
	text-transform: capitalize;
	color: var(--it-common-white);
	background-color: #17254E;
	border-radius: 200px;
	& i {
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		height: 58px;
		width: 58px;
		line-height: 54px;
		border-radius: 50%;
		text-align: center;
		transition: .3s;
		color: var(--it-common-white);
		background-color: #17254E;
		@media #{$xs}{  
			width: 48px;     
			height: 48px;
			line-height: 45px;
		}
	}
	@media #{$lg,$md}{                 
		font-size: 14px;
	}
	@media #{$xs}{                 
		font-size: 14px;
		height: 48px;
		line-height: 48px;
		padding-right: 60px;
	}
	
	&.theme-2 {
		color: var(--it-common-white);
		background-color: var(--it-theme-3);
		& i {
			background-color: #35D7AE;
		}
	}
	&.orange{
		background-color:var(--it-theme-2) ;
		& i {
			background-color: rgb(219, 73, 54);
		}
	}
	&.error-btn {
		padding: 0 25px;
		padding-left: 80px;
		padding-right: 25px;
		& i {
			left: 0;
			right: auto;

		}
	}
	&:hover {
		color: var(--it-common-white);
		background-color: var(--it-common-black-3);
		& i {
			color: var(--it-common-white);
			background-color: #1F3061;
		}
		&.theme-2{
			background-color:var(--it-theme-2) ;
			& i {
				background-color: rgb(219, 73, 54);
			}
		}
	}
}
.ed-btn-dark{
	display: inline-block;
	padding: 0 25px;
	padding-right: 80px;
	height: 58px;
	line-height: 58px;
	position: relative;
	transition: .3s;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.01em;
	text-transform: capitalize;
	color: var(--it-common-white);
	background-color: var(--it-common-black-3);
	border-radius: 200px;
	& i {
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		height: 58px;
		width: 58px;
		line-height: 54px;
		border-radius: 50%;
		text-align: center;
		color: var(--it-common-white);
		background-color: #1F3061;
		transition: .3s;
		@media #{$xs}{      
			width: 48px; 
			height: 48px;
			line-height: 48px;
		}
	}
	@media #{$lg,$md}{                 
		font-size: 14px;
	}
	@media #{$xs}{                 
		font-size: 14px;
		height: 48px;
		line-height: 48px;
		padding-right: 60px;
	}
	&:hover {
		color: var(--it-common-white);
		
		& i {
			color: var(--it-common-white);
			background-color: #344e9d;
		}
	}
}
.ed-btn-yellow{
	display: inline-block;
	padding: 0 25px;
	padding-right: 80px;
	height: 58px;
	line-height: 58px;
	position: relative;
	transition: .3s;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.01em;
	text-transform: capitalize;
	color: var(--it-common-black);
	background-color: var(--it-common-yellow);
	border-radius: 200px;
	& i {
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		height: 58px;
		width: 58px;
		line-height: 54px;
		border-radius: 50%;
		text-align: center;
		color: var(--it-common-black);
		background-color: #FEB65C;
		transition: .3s;
	}
	@media #{$lg,$md,$xs}{                 
		font-size: 14px;
	}
	&:hover {
		color: var(--it-common-white);
		background-color: var(--it-theme-1);
		& i {
			color: var(--it-common-white);
			background-color: #344e9d;
		}
		&.dark-bg {
			color: var(--it-common-white);
			background-color: var(--it-common-black-3);
			& i {
				color: var(--it-common-white);
				background-color: #1F3061;
			}
		}
	}
}
.ed-btn-blog{
	display: inline-block;
	padding: 0 25px;
	padding-right: 60px;
	height: 48px;
	line-height: 48px;
	position: relative;
	transition: .3s;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.01em;
	text-transform: capitalize;
	color: var(--it-theme-1);
	background-color: #E9E2FF;
	border-radius: 200px;
	& i {
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		height: 48px;
		width: 48px;
		line-height: 44px;
		border-radius: 50%;
		text-align: center;
		color: var(--it-theme-1);
		transition: .3s;
		background-color: #E3DBFF;
	}
	&.square {
		border-radius: 5px;
	}
	&.theme-bg-2 {
		color: var(--it-common-white);
		background-color: var(--it-theme-3);
		& i {
			color: var(--it-common-white);
			background-color: #35D7AE;
		}
	}
	&.radius {
		border-radius: 100px;
		padding: 0 27px;
	}
	&:hover {
		color: var(--it-common-white);
		background-color: var(--it-theme-1);
		& i {
			color: var(--it-common-white);
			background-color: var(--it-common-purple-2);
		}
		&.radius {
			background-color: var(--it-theme-7);
		}
	}
	@media #{$lg,$md,$xs}{                 
		font-size: 14px;
	}
}
.ed-btn-blog-square{
	display: inline-block;
	padding: 0 25px;
	padding-right: 55px;
	height: 53px;
	line-height: 53px;
	position: relative;
	transition: .3s;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.01em;
	text-transform: capitalize;
	color: var(--it-theme-1);
	background-color: #E9E2FF;
	border-radius: 5px;
	& i {
		position: absolute;
		top: 0;
		right: 25px;
		display: inline-block;
		color: var(--it-theme-1);
		transition: .3s;
	}
	&:hover {
		color: var(--it-common-white);
		background-color: var(--it-theme-1);
		& i {
			color: var(--it-common-white);
		}
	}
	@media #{$lg,$md,$xs}{                 
		font-size: 14px;
	}
}
.it-btn-white{
	display: inline-block;
	font-weight: 600;
	text-transform: capitalize;
	padding: 0 25px;
	height: 60px;
	line-height: 64px;
	position: relative;
	transition: .4s;
	border-radius: 5px;
	z-index: 1;
	color: var(--it-common-black);
	background-color: var(--it-common-white);
	font-size: 15px;
	@media #{$lg,$md,$xs}{                 
		font-size: 14px;
	}
	& span {
		& svg {
			margin-left: 10px;
			display: inline-block;
			transform: translateY(-1px);
		}
	}
	&:hover{
		& span{
			& svg{
				animation: tfLeftToRight 0.4s forwards;
				transform: translateY(-1px);
			}
		}
	}
	&.sky-bg{
		font-size: 15px;
		background-color: var(--it-theme-3);
		color: var(--it-common-white);
	}
	&.purple-2 {
		background-color: var(--it-theme-4);
		color: var(--it-common-white);
	}
}
.ed-btn-square{
	display: inline-block;
	padding: 0 35px;
	height: 61px;
	line-height: 61px;
	position: relative;
	transition: .3s;
	font-weight: 500;
	font-size: 15px;
	text-transform: uppercase;
	color: var(--it-common-white);
	background-color: var(--it-theme-3);
	text-align: center;
	@media #{$md,$xs}{
		padding: 0 25px;
		height: 51px;
		line-height: 51px;
		font-size: 13px;
	} 
	&.sm {
		height: 51px;
		line-height: 50px;
	}
	&.sm-2 {
		height: 40px;
		line-height: 40px;
		text-transform: capitalize;
		background-color: #E8E8F4;
		color: var(--it-common-purple-3);
	}
	&.purple-2 {
		background-color: var(--it-theme-4);
		color: var(--it-common-white);
	}
	&.purple-3 {
		background-color: var(--it-theme-4);
		color: var(--it-common-white);
	}
	&.purple-4 {
		background-color: #17254E;
		color: var(--it-common-white);
	}
	&.radius {
		border-radius: 5px;
	}
	&.orange {
		background-color: var(--it-common-orange);
		color: var(--it-common-white);
		border-radius: 5px;
		& i,svg {
			display: inline-block;
			margin-left: 12px;
			transform: translateY(-2px);
			@media #{$md,$xs}{
				font-size: 12px;
				margin-left: 5px;
				transform: translateY(-1px);
			}
		}
	}
	&.dark {
		background-color: var(--it-common-black);
		color: var(--it-common-white);
		border-radius: 5px;
		& i,svg {
			display: inline-block;
			margin-left: 12px;
			transform: translateY(-2px);
		}
	}
	&:hover {
		background-color: var(--it-theme-4);
		color: var(--it-common-white);
		&.purple-2 {
			color: var(--it-common-white);
			background-color: var(--it-theme-3);
		}
		&.purple-3 {
			color: var(--it-common-white);
			background-color: var(--it-theme-3);
		}
		&.purple-4 {
			background-color: #dd0000;
			color: var(--it-common-white);
		}
		&.sm-2 {
			background-color: var(--it-common-purple-3);
			color: var(--it-common-white);
		}
		&.hover-2 {
			background-color: var(--it-common-orange);
			color: var(--it-common-white);
		}
	}
}
.ed-btn-radius{
	display: inline-block;
	padding: 0 35px;
	height: 59px;
	line-height:59px;
	position: relative;
	transition: .3s;
	font-weight: 500;
	font-size: 15px;
	text-transform: capitalize;
	color: var(--it-common-white);
	background-color: var(--it-theme-7);
	border-radius: 60px;
	&.sky-bg {
		background-color: var(--it-theme-5);
	}
	&:hover {
		color: var(--it-common-white);
		background-color: var(--it-theme-5);
		&.sky-bg {
			color: var(--it-common-white);
			background-color: var(--it-theme-7);
		}
	}
}
.it-btn{
	display: inline-block;
	font-weight: 600;
	text-transform: capitalize;
	padding: 0 25px;
	height: 54px;
	line-height: 54px;
	position: relative;
	transition: .4s;
	border-radius: 5px;
	z-index: 1;
	color: var(--it-common-white);
	background-color: var(--it-common-purple-3);
	@media #{$lg,$md,$xs}{                 
		font-size: 14px;
	}
	&.large{
		padding: 0px 60px;
	}
	& span {
		& svg {
			margin-left: 10px;
			display: inline-block;
			transform: translateY(-1px);
		}
	}
	&:hover{
		color: var(--it-common-white);
		& span{
			& svg{
				animation: tfLeftToRight 0.4s forwards;
				transform: translateY(-1px);
			}
		}
	}
	&.black-bg {
		background-color: black;
	}
	&.sm {
		height: 53px;
		line-height: 53px;
		padding: 0 20px;
	}
	&.blue-bg {
		background-color: var(--it-theme-4);
	}
}
.it-btn-white-sm {
	display: inline-block;
	font-weight: 700;
	font-size: 14px;
	padding: 0 20px;
	height: 40px;
	width: 140px;
	line-height: 40px;
	height: 40px;
	line-height: 40px;
	position: relative;
	transition: .3s;
	text-transform: uppercase;
	color: var(--it-common-white);
	background-color: var(--it-theme-1);
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	margin: 5px 0;
	&:hover{
		color: var(--it-common-black);
		background-color: var(--it-common-white);
	}
}
