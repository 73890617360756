@use '../utils' as *;

/*----------------------------------------*/
/* 10. choose css start
/*----------------------------------------*/
.it-choose{
    &-left {
        @media #{$md,$xs}{                 
            margin-bottom: 50px;
        }
    }
    &-content { 

        &-box {
            margin-right: 45px;
            @media #{$lg,$md,$xs}{                 
                margin-right: 0;
            }
        }
        border-radius: 8px;   
        padding: 30px 20px;
        background-color: #E9E2FF;    
        & h5 {
            font-size: 17px;
            font-weight: 600;
            line-height: 32px;
            padding-bottom: 8px;
            text-transform: capitalize;
            @media #{$xl}{                 
                font-size: 16px;
            }
            @media #{$lg}{                 
                font-size: 14px;
            }
            @media #{$md,$xs}{                 
                font-size: 16px;
            }
            & i {
                margin-right: 10px;
                display: inline-block;
                color: var(--it-theme-1);
            }
        }
        & p {
            color:#4D5756;
            font-size: 15px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 0;
            padding-right: 60px;
            @media #{$xl}{                 
                font-size: 14px;
            }
            @media #{$lg,$md,$xs}{                 
                font-size: 12px;
                padding-right: 0;
            }
        }
    }
    &-text {
        & p {
            @media #{$lg}{                 
                font-size: 13px;
            }
            @media #{$md,$xs}{                 
                font-size: 14px;
            }
        }
    }
    &-thumb {
        &-box {
            margin-right: 40px;
            @media #{$md,$xs}{                 
                margin-right: 0;
            }
        }
        display: inline-block;
        border: 10px solid var(--it-common-white);
    }
    &-shape {
        &-1 {
            position: absolute;
            top: -30px;
            left: -30px;
            z-index: -1;
        }
        &-2 {
            position: absolute;
            bottom:-30px;
            right: -30px;
            z-index: -1;
        }
        &-3 {
            position: absolute;
            top: -80px;
            right: -85px;
            z-index: -1;
            animation: itupdown 0.8s infinite alternate;
        }
        &-4 {
            position: absolute;
            top: 215px;
            left: 40%;
            animation: itleftright 1s infinite alternate;
            @media #{$xl,$lg,$md,$xs}{                 
                top: 150px;
                left: 35%;
            }
        }
        &-5 {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
            @media #{$xl,$lg,$md,$xs}{                 
                bottom: -20%;
            }
        }
        &-6 {
            position: absolute;
            top: 115px;
            left: 255px;
            z-index: -1;
            animation: itrotate 3s ease-in-out 3s infinite alternate forwards;
        }
        &-7 {
            position: absolute;
            top: 135px;
            right: 180px;
            z-index: -1;
            animation: rotate2 4s linear infinite;
        }
        &-8 {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
}
.it-choose-style-2  {
    & .it-choose-thumb-box {
        @media #{$lg,$md,$xs}{
            margin-bottom: 40px;
        }
    }
    & .it-choose-content {
        border-radius: 5px;
        padding: 23px 23px;
        padding-top: 20px;
        background-color: var(--it-common-white);
        box-shadow: 0px 0px 30px 0px rgba(14, 42, 70, 0.08);
        transition: .5s;
        @media #{$xs}{                 
            flex-wrap: wrap;
        }
        &:hover {
            background-color: var(--it-common-black);
            & .it-choose-icon {
                & span {
                    background-color: var(--it-common-white);
                    color: var(--it-common-black);
                }
            }
            & .it-choose-title {
                color: var(--it-common-white);
            }
            & .it-choose-text {
                & p {
                    color: var(--it-common-white);
                }
            }
        }
    }
    & .it-choose-icon {
        margin-right: 20px;
        & span {
            height: 80px;
            width: 80px;
            line-height: 88px;
            text-align: center;
            display: inline-block;
            background-color: var(--it-common-black);
            border-radius: 50%;
            color: var(--it-common-white);
            transition: .5s;
            font-size: 35px;
            @media #{$xs}{                 
                margin-bottom: 20px;
            }
        }
    }
    & .it-choose-title {
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
        text-transform: capitalize;
        transition: .5s;
    }
    & .it-choose-text {
        & p {
            transition: .5s;
        }
    }
}
.ed-choose {
    &-shape {
        &-1 {
            position: absolute;
            top: -11%;
            right: -11%;
            z-index: -1;
        }
        &-2 {
            position: absolute;
            bottom: 10%;
            right: 4%;
            animation: ittranslateX2 4s infinite alternate;
        }
        &-3 {
            position: absolute;
            bottom: 16%;
            left: 35%;
            animation: itrotate 3s ease-in-out 3s infinite alternate forwards;
        }
        &-4 {
            position: absolute;
            top: 19%;
            left: 36%;
            animation: ittranslateX2 4s infinite alternate;
        }
        &-5 {
            position: absolute;
            top: 0;
            left: -1%;
            animation: itswing-2 3s forwards infinite alternate;
            transform-origin: bottom left;
        }
    }
}