@use '../utils' as *;

/*----------------------------------------*/
/*  25. price css start
/*----------------------------------------*/

.it-price{
    &-item{
        &-wrap{
            background-color: var(--it-common-black);
            &.active{
                background-color: var(--it-common-orange);
                & .it-price-category span {
                    background-color: var(--it-theme-1);
                }
                & .it-price-month span {
                    border: 2px solid var(--it-common-orange);
                    color: var(--it-common-purple-3);
                }
                & .it-price-item::after {
                    background-color: var(--it-common-black);
                }
                & .it-price-list p {
                    color: var(--it-common-white);
                }
                & .it-price-list ul li {
                    color: var(--it-common-white);
                }
                & .ed-btn-square {
                    background-color: var(--it-common-orange);
                    transition: .3s;
                    &:hover {
                        background-color: var(--it-common-purple-3);
                        color: var(--it-common-white);
                    }
                }
            }
        }
        padding: 46px;
        padding-top: 0;
        position: relative;
        overflow: hidden;
        z-index: 1;
        @media #{$xl}{
            padding: 50px 20px;
        }
        @media #{$lg,$md,$xs}{
            padding: 50px 30px;
            padding-top: 30px;
        }
        @media #{$xs}{
            padding: 50px 20px;
            padding-top: 30px;
        }
        &::after{
            position: absolute;
            top: 14%;
            left: 50%;
            transform: translateX(-50%);
            height: 615px;
            width: 615px;
            border-radius: 50%;
            background-color: #F4F4F4;
            content: '';
            z-index: -1;
        }
    }
    &-category{
        & span{
            color: #FFF;
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            height: 50px;
            line-height: 50px;
            background-color: #344e9d;
            display: inline-block;
            padding: 0px 90px;
            transform: translateY(-25px);
            @media #{$xl,$lg,$md,$xs}{
                padding: 0px 60px;
                font-size: 17px;
                margin-top: 0;
            }
        }
    }
    &-month{
        & span{
            height: 128px;
            width: 300px;
            line-height: 130px;
            border-radius: 50%;
            text-align: center;
            display: inline-block;
            color:#dd1111;
            font-size: 35px;
            font-weight: 600;
            text-transform: capitalize;
            background-color: var(--it-common-white);
            border: 2px solid #344e9d;
            margin-top: 20px;
            margin-bottom: 15px;
            & i{
                color:#0E2A46;
                font-size: 14px;
                font-weight: 500;
                text-transform: capitalize;
                font-style: normal;
            }
        }
    }
    &-list{
        & p{
            color:#4D5756;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 30px;
            @media #{$md}{
                font-size: 14px;
            }
        }
        & ul{
            text-align: left;
            margin-bottom: 20px;
            & li{
                list-style-type: none;
                color:#0E2A46;
                font-size: 17px;
                font-weight: 400;
                line-height: 1;
                padding-bottom: 20px;
                padding-left: 30px;
                position: relative;
                @media #{$xl,$lg,$md,$xs}{
                    font-size: 15px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                & i{
                    height: 15px;
                    width: 15px;
                    line-height: 12px;
                    display: inline-block;
                    border-radius: 50%;
                    border: 1px solid var(--it-common-orange);
                    color: var(--it-common-orange);
                    font-size: 10px;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
    &-shape-1 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        z-index: -1;
    }
}