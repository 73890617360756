/* DropdownStyle.css */
.custom-dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
    margin-top: 5px;
  }
  
  .custom-dropdown:focus {
   
    outline: none;
 
  }
  