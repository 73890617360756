@use '../utils' as *;

/*----------------------------------------*/
/*  29. testimonial css start
/*----------------------------------------*/

.it-testimonial-3 {
  &-wrapper {
    border-radius: 5px;
    padding: 75px 70px;
    padding-right: 50px;
    padding-bottom: 60px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -30px;
    @media #{$md} {
      margin: 120px 0;
      padding: 70px 60px;
    }
    @media #{$xs} {
      margin: 120px 0;
      padding: 20px;
    }
  }
  &-thumb {
    & img {
      max-width: inherit;
    }
  }
  &-content {
    & p {
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 45px;
      padding-left: 25px;
      color: var(--it-common-white);
      @media #{$xs} {
        padding-left: 0;
        font-size: 16px;
      }
    }
  }
  &-quote {
    position: absolute;
    top: 40px;
    left: 60px;
  }
  &-author-info {
    & h5 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: var(--it-common-white);
      font-family: var(--it-ff-poppins);
      @media #{$xs} {
        font-size: 16px;
      }
    }
    & span {
      color: var(--it-common-white);
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      @media #{$xs} {
        font-size: 14px;
      }
    }
  }
  &-avata {
    margin-right: 27px;
    position: relative;
    &::after {
      content: '';
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      position: absolute;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(1.2);
      border: 1px dashed var(--it-theme-1);
    }
    & img {
      border-radius: 50%;
      height: 90px;
      width: 90px;
    }
  }

  &-shape {
    &-1 {
      position: absolute;
      bottom: -30px;
      right: -30px;
      z-index: -1;
    }
  }
}

.test-slider-dots {
  position: absolute;
  bottom: 45px;
  right: 50px;
  z-index: 5;
  cursor: pointer;

  & .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    background-color: var(--it-common-white);
    margin: 0 8px;
    z-index: 2;
    opacity: 1;
    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      border: 1px solid var(--it-common-white);
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
    }
  }
  & .swiper-pagination-bullet-active {
    background-color: var(--it-common-white);

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
  &.style-2 {
    position: static;
    & > .swiper-pagination-bullet {
      background-color: var(--it-common-black);
      opacity: 100%;
    }
    & > .swiper-pagination-bullet-active {
      background-color: var(--it-common-yellow);
    }
  }
}
.it-testimonial-5 {
  &-item-wrap {
    & .it-testimonial-3-author-box {
      padding-left: 235px;
      @media #{$lg,$md,$xs} {
        padding-left: 0;
        justify-content: center;
      }
    }
    & .it-testimonial-3-author-info {
      & h5 {
        color: var(--it-common-black);
      }
      & span {
        color: var(--it-common-black);
      }
    }
    & .it-testimonial-3-avata {
      &::after {
        border-color: var(--it-theme-4);
      }
    }
  }
  &-item {
    padding: 75px 50px;
    padding-right: 65px;
    border-radius: 20px;
    background-color: var(--it-theme-4);
    position: relative;
    &::after {
      position: absolute;
      content: '';
      bottom: -7%;
      left: 20%;
      width: 115px;
      height: 100px;
      background-color: var(--it-theme-4);
      clip-path: polygon(0 0, 82% 0, 100% 100%);
      transform: rotate(-145deg);
    }
    @media #{$lg} {
      padding: 40px 30px;
      padding-right: 30px;
    }
    @media #{$xs} {
      padding: 30px;
      padding-right: 30px;
    }
  }
  &-text {
    & p {
      font-size: 17px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 0;
      color: var(--it-common-white);
      @media #{$lg,$md,$xs} {
        font-size: 14px;
      }
    }
  }
  &-star {
    & i {
      color: var(--it-theme-5);
    }
  }
  &-shape {
    &-1 {
      position: absolute;
      left: 35px;
      bottom: 300px;
      animation: rotate2 12s linear infinite;
      @media #{$xxl} {
        bottom: 100px;
      }
    }
    &-2 {
      position: absolute;
      top: 160px;
      left: 355px;
      @media #{$xl} {
        left: 100px;
      }
    }
    &-3 {
      position: absolute;
      top: 140px;
      right: 255px;
      animation: itswing 1s forwards infinite alternate;
    }
    &-4 {
      position: absolute;
      bottom: 260px;
      right: 70px;
      @media #{$xxl} {
        bottom: 100px;
      }
    }
    &-5 {
      position: absolute;
      top: 140px;
      left: 250px;
      animation: rotate2 4s linear infinite;
    }
    &-6 {
      position: absolute;
      top: 170px;
      right: 110px;
      animation: itupdown 0.8s infinite alternate;
    }
    &-7 {
      position: absolute;
      left: 75px;
      bottom: 230px;
    }
  }
  &-active {
    padding: 20px;
    margin: -20px;
  }
}
.it-testimonial-style-2 {
  & .it-testimonial-5-item {
    padding: 50px;
    padding-right: 75px;
    padding-bottom: 40px;
    border-radius: 0;
    background-color: var(--it-common-black);
    @media #{$xs} {
      padding: 50px 20px;
      padding-bottom: 40px;
    }
    &::after {
      display: none;
    }
  }
  & .it-testimonial-3-avata {
    border-radius: 50%;
    border: 2px solid var(--it-common-white);
    &::after {
      display: none;
    }
  }
  & .it-testimonial-3-author-info {
    & span {
      color: var(--it-theme-4);
    }
  }
  & .it-testimonial-5-star {
    & i {
      font-size: 14px;
      color: #d3a500;
    }
  }
  & .it-testimonial-5-quote {
    position: absolute;
    top: 50px;
    right: 65px;
    @media #{$xl,$lg} {
      right: 20px;
    }
  }
}
.it-testimonial-4-style {
  & .it-testimonial-3-content p {
    color: var(--it-common-black);
  }
  & .it-testimonial-3-author-info h5 {
    color: var(--it-common-black);
  }
  & .it-testimonial-3-author-info span {
    color: var(--it-common-black);
  }
  & .test-slider-dots .swiper-pagination-bullet {
    background-color: var(--it-common-black);
  }
  & .swiper-pagination-bullet {
    opacity: 1;
  }
}

.it-sub-bg-none {
  & .it-section-subtitle {
    background-color: transparent;
  }
}

.ed-testimonial {
  &-ptb {
    margin-top: -100px;
    padding-top: 220px;
    padding-bottom: 120px;
    background-size: cover;
  }
  &-item {
    padding: 30px 30px;
    padding-right: 46px;
    border: 1px solid var(--it-common-black-3);
    border-radius: 20px;
  }
  &-author-box {
    & h5 {
      font-weight: 700;
      font-size: 20px;
    }
    & span {
      font-size: 17px;
      color: var(--it-theme-1);
    }
  }
  &-quote {
    position: absolute;
    top: -30px;
    left: -20px;
  }
  &-ratting {
    margin-bottom: 5px;
    & i {
      font-size: 14px;
      color: #fc6441;
    }
  }
}

.ed-testimonial-style-2 {
  & .ed-testimonial-item {
    border-radius: 10px;
    border: 2px dashed var(--it-common-orange);
  }
  & .ed-testimonial-author-box {
    & h5 {
      font-size: 17px;
      margin-bottom: 0;
    }
    & span {
      font-size: 13px;
    }
  }
}
.ed-testimonial-style-3 {
  & .ed-testimonial-item {
    border: 2px dashed var(--it-theme-7);
  }
  & .ed-testimonial-author-box {
    & span {
      color: var(--it-theme-5);
    }
  }
  & .ed-testimonial-ratting {
    & i {
      color: var(--it-theme-7);
    }
  }
  & .ed-testimonial-shape-1 {
    position: absolute;
    top: 4%;
    right: 0;
    animation: zoom 13s infinite;
  }
  & .ed-testimonial-shape-2 {
    position: absolute;
    bottom: 23%;
    left: 5%;
    animation: ittranslateY2 4s infinite alternate;
    @media #{$xxxl} {
      bottom: 3%;
    }
  }
  &.inner-style {
    & .ed-testimonial-author-box {
      & span {
        color: var(--it-common-purple-3);
      }
    }
  }
}

.ed-testimonial-active {
  padding: 30px !important;
  margin: -30px !important;
}
.it-testimonial-3-active {
  padding: 20px !important;
  margin: -20px !important;
}
