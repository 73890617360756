

.tp-img-reveal[data-fx="1"]::after,
.tp-img-reveal[data-fx="2"]::after {
	content: '';
	z-index: -1;
	width: 100%;
	bottom: 0.25rem;
	left: 0;
	position: absolute;
	height: 2px;
	background: currentColor;
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
	transition: transform 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	text-align: center;
}

.tp-img-reveal[data-fx="1"]::after,
.tp-img-reveal[data-fx="2"]::after {
	bottom: 0;
}

.tp-img-reveal[data-fx="1"]:hover::after,
.tp-img-reveal[data-fx="2"]:hover::after {
	transform: scale3d(1,1,1)
}

.tp-img-reveal[data-fx="6"]::after {
	content: '';
	z-index: -1;
	width: 100%;
	bottom: 0.25rem;
	left: 0;
	position: absolute;
	height: 2px;
	background: currentColor;
	transform: scale3d(0,1,1);
	transform-origin: 100% 50%;
	transition: transform 0.7s cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

.tp-img-reveal[data-fx="6"]::after {
	bottom: 0;
}


.tp-img-reveal[data-fx="6"]:hover::after {
	transform: scale3d(1,1,1)
}


.tp-img-reveal[data-fx="14"]::after {
	content: '';
	z-index: -1;
	width: 100%;
	bottom: 0.25rem;
	left: 0;
	position: absolute;
	height: 2px;
	background: currentColor;
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
}

.tp-img-reveal[data-fx="14"]::after {
	bottom: 0;
}

.tp-img-reveal[data-fx="14"]:hover::after {
	animation: loop 0.5s infinite;
}

@keyframes loop {
	0% { transform-origin: 0% 50%; transform: scale3d(0,1,1); }
	50% { transform-origin: 0% 50%; transform: scale3d(1,1,1); }
	51% { transform-origin: 100% 50%; }
	100% { transform-origin: 100% 50%; transform: scale3d(0,1,1); }
}

.tp-img-reveal-wrapper {
	position: fixed;
	width: 250px;
	height: 270px;
	top: 0;
	left: 0;
	pointer-events: none;
	opacity: 0;
	z-index: 9;
	
}

.tp-img-reveal-wrapper__inner,
.tp-img-reveal-wrapper__img {
	width: 100%;
	height: 100%;
	position: relative;
}

.tp-img-reveal-wrapper__deco {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #181314;
}

.tp-img-reveal-wrapper__img {
	background-size: cover;
	background-position: 50% 50%;
}



.test-2{
    padding: 50px;
    background-color: #292930;
}